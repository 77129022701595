// Import Vue and its plugins
import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import BootstrapVue from 'bootstrap-vue';
import VueTheMask from 'vue-the-mask';
import VueCookies from 'vue-cookies';
import VueSession from 'vue-session';
import VueScript2 from 'vue-script2';
import VTooltip from 'v-tooltip';
import VeeValidate from 'vee-validate';
import * as Sentry from '@sentry/vue';
import * as VueGoogleMaps from 'gmap-vue';

// Import local modules
import router from './router';
import store from './store/index';
import App from './App.vue';
import './registerServiceWorker';

// Import page layouts
import Default from './layouts/Default.vue';
import Blank from './layouts/Blank.vue';
import Basic from './layouts/Basic.vue';
import External from './layouts/External.vue';

// Import version from package.json
import { version } from '../package.json';

// Use VUE_APP_ENV_NAME for Aira specific environment
// e.g prod, dev, qa1 and local
if (process.env.VUE_APP_ENV_NAME !== 'local') {
  // Initialize Vue Analytics
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
  });

  // Initialize Sentry
  Sentry.init({
    Vue,
    dsn: 'https://ac3a422f3080437eb7f257eaceb38ee6@sentry.io/1315855',
    release: process.env.NODE_ENV === 'development' ? 'local' : version,
    environment: process.env.VUE_APP_ENV_NAME,
  });
}

// Register layout components
Vue.component('DefaultLayout', Default);
Vue.component('BlankLayout', Blank);
Vue.component('BasicLayout', Basic);
Vue.component('ExternalLayout', External);

// Register plugins
Vue.use(BootstrapVue);
Vue.use(VueTheMask);
Vue.use(VueCookies);
Vue.use(VueScript2);
Vue.use(VTooltip);
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors',
});

// Initialize Vue Session
Vue.use(VueSession, {
  persist: true,
});

// Initialize Vue Google Maps
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: ['places'],
  },
  installComponents: true,
});

// Disable production tip
Vue.config.productionTip = false;

// Create a new Vue instance
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
